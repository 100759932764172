import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { omit } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ImportDeviceRequest, importDeviceSchema } from './validation-schema';
import { CreateDeviceMutationVariables, GetAdminDevicesQuery } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants';
import { MUTATION_CREATE_DEVICE } from '../../../../services/mutations';
import {
  QUERY_GET_COMPANY_NAMES,
  QUERY_GET_CONNECTOR_HOLDER_TYPES,
  QUERY_GET_DEVICE_TYPES,
  QUERY_GET_SITE_NAMES
} from '../../../../services/queries';
import { CompanyType } from '../../../../types';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { Loading, RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';
import { ErrorPage } from '../../error-page';

interface ImportDeviceDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenImportDevice: Dispatch<SetStateAction<boolean>>;
  refetchDevices: () => Promise<ApolloQueryResult<GetAdminDevicesQuery>>;
}

export const ImportDeviceDrawer = ({ setOpenImportDevice, refetchDevices, ...props }: ImportDeviceDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingCustomerNames,
    data: dataCustomerNames,
    error: errorCustomerNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, {
    fetchPolicy: 'network-only',
    skip: !props.open,
    variables: { filters: { companyType: { _eq: CompanyType.Customer }, customer: { allowImport: { _eq: true } } } }
  });
  const {
    loading: loadingDeviceTypes,
    error: errorDeviceTypes,
    data: dataDeviceTypes
  } = useQuery(QUERY_GET_DEVICE_TYPES, { fetchPolicy: 'network-only', skip: !props.open });
  const {
    loading: loadingConnectorHolderTypes,
    error: errorConnectorHolderTypes,
    data: dataConnectorHolderTypes
  } = useQuery(QUERY_GET_CONNECTOR_HOLDER_TYPES, { fetchPolicy: 'network-only', skip: !props.open });
  const [importDevice, { loading: loadingImportDevice }] = useMutation(MUTATION_CREATE_DEVICE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('devicesAdminPage.importDeviceDrawer.importDeviceFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<ImportDeviceRequest>({
    resolver: zodResolver(importDeviceSchema),
    defaultValues: { serialNumber: '', description: '' }
  });
  const watchCustomerId = watch('customerId');
  const {
    loading: loadingSites,
    data: dataSites,
    error: errorSites
  } = useQuery(QUERY_GET_SITE_NAMES, {
    fetchPolicy: 'no-cache',
    variables: { filters: { customerId: { _eq: watchCustomerId } } },
    skip: !watchCustomerId
  });

  const customersResponse = dataCustomerNames?.companies || [];
  const customerOptions: RSSelectItemProps[] = customersResponse.map((customer) => ({
    displayName: customer.name,
    menuItemProps: { value: customer.id }
  }));
  const deviceTypesResponse = dataDeviceTypes?.deviceTypes || [];
  const deviceTypeOptions: RSSelectItemProps[] = deviceTypesResponse.map((deviceType) => ({
    displayName: deviceType.name,
    menuItemProps: { value: deviceType.id }
  }));
  const connectorHolderTypesResponse = dataConnectorHolderTypes?.connectorHolderTypes || [];
  const connectorHolderTypeOptions: RSSelectItemProps[] = connectorHolderTypesResponse.map((connectorHolderType) => ({
    displayName: connectorHolderType.name,
    menuItemProps: { value: connectorHolderType.id }
  }));
  const sitesResponse = dataSites?.sites || [];
  const siteOptions: RSSelectItemProps[] = sitesResponse.map((site) => ({
    displayName: site.name,
    menuItemProps: { value: site.id }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenImportDevice(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenImportDevice(false);
    }
  };

  const onSubmit: SubmitHandler<ImportDeviceRequest> = (data): void => {
    const dataToSubmit: CreateDeviceMutationVariables = omit(data, 'customerId');
    if (dataToSubmit.description === '') {
      dataToSubmit.description = undefined;
    }

    if (!isDirty) {
      setOpenImportDevice(false);
      setShowConfirmationModal(false);
      return;
    }

    importDevice({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(
          t('devicesAdminPage.importDeviceDrawer.importDeviceSuccess'),
          undefined,
          undefined,
          'success'
        );
        reset();
        refetchDevices();
        setOpenImportDevice(false);
        setShowConfirmationModal(false);
        navigate(`${appConfig.basePath}/admin/devices/${responseData.createDevice.device!.id}`);
      }
    });
  };

  const groupedErrors = errorCustomerNames || errorConnectorHolderTypes || errorDeviceTypes || errorSites;
  if (groupedErrors) {
    return (
      <RSDrawer {...props} className="import-device-drawer">
        <ModalDrawerHeader title={t('devicesAdminPage.importDeviceDrawer.title')} />
        <div className="import-device-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={groupedErrors.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('devicesAdminPage.importDeviceDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  if (loadingCustomerNames || loadingConnectorHolderTypes || loadingDeviceTypes) {
    return (
      <RSDrawer {...props} className="import-device-drawer">
        <ModalDrawerHeader title={t('devicesAdminPage.importDeviceDrawer.title')} />
        <div className="import-device-drawer__form">
          <div className="import-device-drawer__loading">
            <Loading />
          </div>
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
            saveButtonText={t('devicesAdminPage.importDeviceDrawer.submitForm')}
          />
        </div>
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="import-device-drawer">
      <ModalDrawerHeader title="Import ROC" />
      <form className="import-device-drawer__form" data-testid="import-device-drawer-form">
        <div className="import-device-drawer__input-fields">
          <RSTextInput
            inputLabel={t('devicesAdminPage.importDeviceDrawer.serialNumber')}
            required={true}
            data-testid="import-device-serial-number-input"
            helperText={errors.serialNumber?.message}
            error={Boolean(errors.serialNumber)}
            {...register('serialNumber')}
          />
          <Controller
            name="customerId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="import-device-drawer__customer-select"
                inputLabel={t('devicesAdminPage.importDeviceDrawer.customer')}
                required={true}
                menuItems={customerOptions}
                helperText={errors.customerId?.message}
                error={Boolean(errors.customerId)}
                onBlur={onBlur}
                onChange={(event) => {
                  onChange(event);
                  setValue('siteId', '');
                }}
                data-testid="import-device-drawer-company-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <Controller
            name="siteId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="import-device-drawer__site-select"
                inputLabel={t('devicesAdminPage.importDeviceDrawer.site')}
                required={true}
                menuItems={siteOptions}
                helperText={errors.siteId?.message}
                error={Boolean(errors.siteId)}
                disabled={!dataSites || loadingSites}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="import-device-drawer-site-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <Controller
            name="deviceTypeId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="import-device-drawer__device-type-select"
                inputLabel={t('devicesAdminPage.importDeviceDrawer.deviceType')}
                required={true}
                menuItems={deviceTypeOptions}
                helperText={errors.deviceTypeId?.message}
                error={Boolean(errors.deviceTypeId)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="import-device-drawer-device-type-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <Controller
            name="connectorHolderTypeId"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="import-device-drawer__connector-holder-type-select"
                inputLabel={t('devicesAdminPage.importDeviceDrawer.connectorHolderType')}
                menuItems={connectorHolderTypeOptions}
                helperText={errors.connectorHolderTypeId?.message}
                error={Boolean(errors.connectorHolderTypeId)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="import-device-drawer-connector-holder-type-select"
                value={value || ''}
                {...RS_SELECT_ADMIN_MENU_PROPS}
              />
            )}
          />
          <RSTextInput
            inputLabel={t('devicesAdminPage.importDeviceDrawer.description')}
            data-testid="import-device-drawer-description-input"
            multiline={true}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description')}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingImportDevice}
          isCancelDisabled={loadingImportDevice}
          colorVariant="success"
          saveButtonText={t('devicesAdminPage.importDeviceDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};
