import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_DEVICE = gql(`
  mutation createDevice(
    $serialNumber: String!
    $siteId: String!
    $deviceTypeId: String!
    $connectorHolderTypeId: String
    $description: String
  ) {
    createDevice(
      serialNumber: $serialNumber
      siteId: $siteId
      deviceTypeId: $deviceTypeId
      connectorHolderTypeId: $connectorHolderTypeId
      description: $description
      createOnly: true
    ) {
      device {
        id
        serialNumber
        site {
          id
          name
          customer {
            companyId
            company {
              id
              name
            }
          }
        }
        deviceType {
          id
          name
        }
        connectorHolderType {
          id
          name
        }
        description
      }
    }
  }
`);
