import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_DEVICE_GROUP = gql(`
  mutation createDeviceGroup($name: String!, $customerId: String!, $serviceProviderId: String, $description: String) {
    createDeviceGroup(
      name: $name
      description: $description
      serviceProviderId: $serviceProviderId
      customerId: $customerId
    ) {
      deviceGroup {
        id
        name
        description
        customer {
          companyId
          company {
            id
            name
          }
        }
        serviceProvider {
          companyId
          company {
            id
            name
          }
        }
      }
    }
  }
`);
