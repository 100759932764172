import classNames from 'classnames';
import { ComponentProps, PropsWithChildren } from 'react';

import { DataTestId } from '../../../types';

export interface RSActionLinkProps extends PropsWithChildren, DataTestId, ComponentProps<'button'> {
  extraClassNames?: string[];
}

export const RSActionLink = ({
  extraClassNames,
  'data-testid': dataTestId,
  children,
  ...props
}: RSActionLinkProps): JSX.Element => {
  return (
    <button
      {...props}
      className={classNames('rs-action-link', extraClassNames)}
      data-testid={dataTestId || 'rs-action-link'}
    >
      {children}
    </button>
  );
};
