/* eslint-disable func-style */
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { isBoolean } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Action,
  AuthCompany,
  AuthConnectorHolderType,
  AuthCustomer,
  AuthDevice,
  AuthDeviceBasicDetails,
  AuthDeviceCloudSettings,
  AuthDeviceGroup,
  AuthDeviceGroupDevice,
  AuthDeviceSite,
  AuthDeviceType,
  AuthRocAlertConfiguration,
  AuthSite,
  AuthUser,
  AuthUserDeviceGroup,
  AuthzCheckerService,
  Subject
} from './authz-checker.service';
import { AuthSubjectInfo, wrapDeviceOperationData, wrapDeviceQueryData, wrapUserData } from './authz-checker.types';
import { QUERY_GET_DEVICE_WITH_GROUP_INFO, QUERY_GET_USER_AUTH_INFO } from '../queries';

// usage example
//
// one-call API: provide subjectInfo and action - the hook will collect all the data and return the permission
//
// const userCanDoOperation = useAuthCheckerWithSubjectInfo({
//   action: 'UPDATE',
//   subjectInfo: {
//     type: 'DeviceOperation',
//     deviceId: deviceId || '',
//     operationName: operationName || ''
//   },
//   skip: !deviceId || !operationName
// });
//
//
//  2 -step API
//  1. useAuthCheckerSubject - collect the subject
//  2. useAuthChecker - provide subject and action - the hook will return the permission
//
// const subject = useAuthCheckerSubject({
//   subjectInfo: {
//     type: 'DeviceOperation',
//     deviceId: deviceId || '',
//     operationName: operationName || ''
//   },
//   skip: !deviceId || !operationName
// });
//
// const userCanDoOperation = useAuthCheckerWithSubjectInfo({
//   action: 'UPDATE',
//   subject,
//   skip: !deviceId || !operationName
// });
//
// you can also create Auth* objects manually and use them with useAuthChecker
// (see AuthzCheckerService for the list of available Auth* objects)
// this will be useful if you need to check permissions for a lot of objects and want to avoid server round-trips
//
interface AuthCheckerWithSubjectInfoResult {
  result?: boolean;
  loading: boolean;
}

interface AuthCheckerSubjectResult {
  subject: Subject | null;
  loading: boolean;
}

export function useAuthCheckerSubject(param: {
  subjectInfo: AuthSubjectInfo;
  skip: boolean;
}): AuthCheckerSubjectResult {
  const { subjectInfo, skip } = param;
  let deviceId = null;
  switch (subjectInfo?.type) {
    case 'DeviceOperation':
    case 'DeviceGroupDevice':
    case 'DeviceCloudSettings':
    case 'DeviceBasicDetails':
    case 'DeviceSite':
    case 'Device':
      deviceId = subjectInfo.deviceId;
      break;
  }

  const { data: deviceData, loading: deviceLoading } = useQuery(QUERY_GET_DEVICE_WITH_GROUP_INFO, {
    variables: { deviceId: deviceId || '' },
    skip: skip || !deviceId
  });

  const [subject, setSubject] = useState<Subject | null>(null);
  useEffect(() => {
    let result: Subject | null = null;
    if (skip) {
      return;
    }
    switch (subjectInfo?.type) {
      case 'DeviceOperation':
        result = wrapDeviceOperationData(deviceData, subjectInfo.operationName);
        break;
      case 'Company':
        result = new AuthCompany(subjectInfo.companyId);
        break;
      case 'Site':
        result = new AuthSite(new AuthCustomer(subjectInfo.customerId));
        break;
      case 'DeviceGroup':
        result = new AuthDeviceGroup(subjectInfo.customerId, subjectInfo.serviceProviderId, subjectInfo.deviceGroupId);
        break;
      case 'DeviceGroupDevice':
        // eslint-disable-next-line no-case-declarations
        const authDevice = wrapDeviceQueryData(deviceData);
        // public readonly deviceGroup: AuthDeviceGroup, public readonly device: AuthDevice)
        if (authDevice) {
          result = new AuthDeviceGroupDevice(
            new AuthDeviceGroup(subjectInfo.customerId, subjectInfo.serviceProviderId, subjectInfo.deviceGroupDeviceId),
            authDevice
          );
        }
        break;
      case 'Device':
        if (subjectInfo.deviceId) {
          result = wrapDeviceQueryData(deviceData);
        } else {
          result = new AuthDevice();
        }
        break;
      case 'DeviceSite':
        result = new AuthDeviceSite(wrapDeviceQueryData(deviceData) || undefined);
        break;
      case 'DeviceBasicDetails':
        result = new AuthDeviceBasicDetails(wrapDeviceQueryData(deviceData) || undefined);
        break;
      case 'DeviceCloudSettings':
        result = new AuthDeviceCloudSettings(wrapDeviceQueryData(deviceData) || undefined);
        break;
      case 'User':
        result = new AuthUser(
          subjectInfo.email,
          subjectInfo.companyId,
          subjectInfo.isSuperUser,
          subjectInfo.permissions,
          undefined,
          subjectInfo.id
        );
        break;
      case 'UserDeviceGroup':
        result = new AuthUserDeviceGroup(
          new AuthDeviceGroup(subjectInfo.customerId, subjectInfo.serviceProviderId, subjectInfo.deviceGroupId),
          subjectInfo.userRole
        );
        break;
      case 'ConnectorHolderType':
        result = new AuthConnectorHolderType();
        break;
      case 'DeviceType':
        result = new AuthDeviceType();
        break;
      case 'RocAlertConfiguration':
        result = new AuthRocAlertConfiguration(subjectInfo.companyId);
        break;
    }
    setSubject(result);
  }, [deviceData, skip]);
  return { subject, loading: deviceLoading };
}

export function useAuthChecker(params: { action: Action; subject: Subject } | null) {
  const { user } = useAuth0();
  const [userCanDoOperation, setUserCanDoOperation] = useState<boolean | undefined>(undefined);
  const subject = params?.subject;
  const { data: userData, loading: userLoading } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: !user?.sub
  });
  useEffect(() => {
    const authUser = wrapUserData(userData);
    if (authUser && subject) {
      const canDo = new AuthzCheckerService().can(authUser, params.action, subject);
      setUserCanDoOperation(canDo);
      return;
    }
    if (!params) {
      setUserCanDoOperation(false);
      return;
    }
  }, [subject, userData]);
  return { result: userCanDoOperation, loading: userLoading || !isBoolean(userCanDoOperation) };
}

export function useAuthCheckerWithSubjectInfo(params: {
  action: Action;
  subjectInfo: AuthSubjectInfo;
  skip: boolean;
}): AuthCheckerWithSubjectInfoResult {
  const { action, subjectInfo, skip } = params;
  const subject = useAuthCheckerSubject({ subjectInfo, skip });
  const result = useAuthChecker(skip || !subject.subject ? null : { action, subject: subject.subject });
  return { result: result.result, loading: subject.loading || result.loading };
}
