import { gql } from '../../../__generated__/gql';

export const MUTATION_MODIFY_DEVICE = gql(`
  mutation modifyDevice(
    $id: uuid!
    $remarks: String
    $operationalLifeCycle: OperationalLifeCycle
    $description: String
    $connectorHolderTypeId: uuid
    $deviceTypeId: uuid
    $location: LocationInput
    $siteId: uuid
    $moveDeviceWithData: Boolean
    $deactivated: Boolean
  ) {
    modifyDevice(
      id: $id
      remarks: $remarks
      operationalLifeCycle: $operationalLifeCycle
      description: $description
      connectorHolderTypeId: $connectorHolderTypeId
      deviceTypeId: $deviceTypeId
      location: $location
      siteId: $siteId
      moveDeviceWithData: $moveDeviceWithData
      deactivated: $deactivated
    ) {
      id
      device {
        id
        remarks
        description
        updatedAt
        deactivated
        location {
          id
          latitude
          longitude
        }
        deviceType {
          id
          name
        }
        program {
          id
          name
        }
        connectorHolderType {
          id
          code
          connectorType
          name
        }
        site {
          customer {
            companyId
            company {
              id
              name
            }
          }
          id
          name
        }
        operationalLifeCycle
      }
    }
  }
`);
