import { z } from 'zod';

import i18n from '../../../../../i18n';

export const addDeviceGroupSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  customerId: z.string().uuid(),
  // Optional field
  serviceProviderId: z.preprocess((arg) => (arg === '' ? null : arg), z.string().uuid().nullish()),
  description: z.string().nullish()
});

export type AddDeviceGroupRequest = z.infer<typeof addDeviceGroupSchema>;
