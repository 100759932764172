import { z } from 'zod';

import i18n from '../../../../../i18n';

export const importDeviceSchema = z.object({
  serialNumber: z
    .string({
      required_error: i18n.t('forms.inputValidation.emptyInput'),
      invalid_type_error: i18n.t('forms.inputValidation.emptyInput')
    })
    .trim()
    .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
  customerId: z.string().uuid({ message: i18n.t('forms.inputValidation.emptyInput') }),
  siteId: z
    .string({ required_error: i18n.t('forms.inputValidation.emptyInput') })
    .uuid({ message: i18n.t('forms.inputValidation.emptyInput') }),
  deviceTypeId: z
    .string({ required_error: i18n.t('forms.inputValidation.emptyInput') })
    .uuid({ message: i18n.t('forms.inputValidation.emptyInput') }),
  // optional field
  connectorHolderTypeId: z.preprocess((arg) => (arg === '' ? null : arg), z.string().uuid().nullish()),
  description: z.string().nullish()
});

export type ImportDeviceRequest = z.infer<typeof importDeviceSchema>;
