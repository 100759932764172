import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_CUSTOMER = gql(`
  mutation createCustomer($name: String!, $allowImport: Boolean!, $maxDeviceGroupRole: UserDeviceGroupUserRole!, $documentCenterUrl: String) {
    createCustomer(name: $name, allowImport: $allowImport, maxDeviceGroupRole: $maxDeviceGroupRole, documentCenterUrl: $documentCenterUrl) {
      id
      customer {
        companyId
        company {
          id
          name
          maxDeviceGroupRole
          customer {
            companyId
            allowImport
            documentCenterUrl
          }
        }
      }
    }
  }
`);
