import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, omit } from 'lodash';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModifyDeviceRequest, modifyDeviceSchema } from './validation-schema';
import { ModifyDeviceMutationVariables } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { RS_SELECT_ADMIN_MENU_PROPS } from '../../../../constants';
import { MUTATION_MODIFY_DEVICE } from '../../../../services/mutations';
import {
  QUERY_GET_COMPANY_NAMES,
  QUERY_GET_CONNECTOR_HOLDER_TYPES,
  QUERY_GET_DEVICE_TYPES,
  QUERY_GET_SITE_NAMES
} from '../../../../services/queries';
import { CompanyType } from '../../../../types';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { Loading, RSSelect, RSSelectItemProps, RSSwitch, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';
import { ErrorPage } from '../../error-page';

interface ModifyDeviceDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenModifyDevice: Dispatch<SetStateAction<boolean>>;
  defaultValues: ModifyDeviceRequest;
  createdAt: string;
  program?: string;
}

export const ModifyDeviceDrawer = ({
  setOpenModifyDevice,
  defaultValues,
  createdAt,
  program,
  ...props
}: ModifyDeviceDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingCustomers,
    data: dataCustomers,
    error: errorCustomers
  } = useQuery(QUERY_GET_COMPANY_NAMES, {
    variables: { filters: { companyType: { _eq: CompanyType.Customer } } },
    skip: !props.open,
    fetchPolicy: 'no-cache'
  });
  const {
    loading: loadingDeviceTypes,
    error: errorDeviceTypes,
    data: dataDeviceTypes
  } = useQuery(QUERY_GET_DEVICE_TYPES, { skip: !props.open, fetchPolicy: 'no-cache' });
  const {
    loading: loadingConnectorHolderTypes,
    error: errorConnectorHolderTypes,
    data: dataConnectorHolderTypes
  } = useQuery(QUERY_GET_CONNECTOR_HOLDER_TYPES, { skip: !props.open, fetchPolicy: 'no-cache' });
  const [modifyDevice, { loading }] = useMutation(MUTATION_MODIFY_DEVICE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    reset
  } = useForm<ModifyDeviceRequest>({
    resolver: zodResolver(modifyDeviceSchema),
    defaultValues
  });
  const watchCustomerId = watch('customerId');
  const {
    loading: loadingSites,
    data: dataSites,
    error: errorSites
  } = useQuery(QUERY_GET_SITE_NAMES, {
    fetchPolicy: 'no-cache',
    variables: { filters: { customerId: { _eq: watchCustomerId } } },
    skip: !watchCustomerId || !props.open
  });

  const customerResponse = dataCustomers?.companies || [];
  const customerOptions: RSSelectItemProps[] = customerResponse.map((customer) => ({
    displayName: customer.name,
    menuItemProps: { value: customer.id }
  }));
  const deviceTypeResponse = dataDeviceTypes?.deviceTypes || [];
  const deviceTypeOptions: RSSelectItemProps[] = deviceTypeResponse.map((deviceType) => ({
    displayName: deviceType.name,
    menuItemProps: { value: deviceType.id }
  }));
  const connectorHolderTypeResponse = dataConnectorHolderTypes?.connectorHolderTypes || [];
  const connectorHolderTypeOptions: RSSelectItemProps[] = connectorHolderTypeResponse.map((connectorHolderType) => ({
    displayName: connectorHolderType.name,
    menuItemProps: { value: connectorHolderType.id }
  }));
  const siteResponse = dataSites?.sites || [];
  const siteOptions: RSSelectItemProps[] = siteResponse.map((site) => ({
    displayName: site.name,
    menuItemProps: { value: site.id }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenModifyDevice(false);
  };

  const handleCancel = (): void => {
    if (!isEmpty(dirtyFields)) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenModifyDevice(false);
    }
  };

  const onSubmit: SubmitHandler<ModifyDeviceRequest> = (data): void => {
    if (isEmpty(dirtyFields)) {
      setOpenModifyDevice(false);
      setShowConfirmationModal(false);
      return;
    }

    const dataToSubmit = {
      ...omit(data, ['customerId', 'keepDataAtPreviousSite']),
      moveDeviceWithData: !data.keepDataAtPreviousSite
    } as ModifyDeviceMutationVariables;

    modifyDevice({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        reset(data);
        setOpenModifyDevice(false);
        setShowConfirmationModal(false);

        if (!data.keepDataAtPreviousSite) {
          navigate(`${appConfig.basePath}/admin/devices/${responseData.modifyDevice.id}`);
        }
      }
    });
  };

  const groupedError = errorCustomers || errorDeviceTypes || errorConnectorHolderTypes || errorSites;
  if (groupedError) {
    return (
      <RSDrawer {...props} className="modify-device-drawer">
        <ModalDrawerHeader title={t('deviceAdminDetailsPage.modifyDeviceDrawer.title')} />
        <div className="modify-device-drawer__form">
          <ErrorPage
            titleEmphasized={t('apolloErrorPage.errorCode')}
            title={t('apolloErrorPage.errorTitle')}
            message={groupedError.message}
          />
          <DrawerButtonsGroup
            handleCancel={handleLeave}
            isSaveDisabled={true}
            isCancelDisabled={false}
            colorVariant="success"
          />
        </div>
      </RSDrawer>
    );
  }

  const groupedLoading = loadingCustomers || loadingDeviceTypes || loadingConnectorHolderTypes || loadingSites;
  if (groupedLoading) {
    <RSDrawer {...props} className="modify-device-drawer">
      <ModalDrawerHeader title={t('deviceAdminDetailsPage.modifyDeviceDrawer.title')} />
      <div className="modify-device-drawer__form">
        <div className="modify-device-drawer__loading">
          <Loading />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleLeave}
          isSaveDisabled={true}
          isCancelDisabled={false}
          colorVariant="success"
        />
      </div>
    </RSDrawer>;
  }

  return (
    <RSDrawer {...props} className="modify-device-drawer">
      <ModalDrawerHeader title={t('deviceAdminDetailsPage.modifyDeviceDrawer.title')} />
      {/* Check the existence of the data to prevent MUI from complaining out-of-range values in select components */}
      {dataCustomers && dataConnectorHolderTypes && dataDeviceTypes && (
        <>
          <form
            className="modify-device-drawer__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="modify-device-drawer-form"
          >
            <div className="modify-device-drawer__input-fields">
              <Controller
                name="customerId"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSelect
                    className="modify-device-drawer__customer-id-select"
                    inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.customer')}
                    required={true}
                    menuItems={customerOptions}
                    helperText={errors.customerId?.message}
                    error={Boolean(errors.customerId)}
                    onBlur={onBlur}
                    onChange={(event) => {
                      onChange(event);
                      setValue('siteId', '');
                      setValue('keepDataAtPreviousSite', true);
                    }}
                    data-testid="modify-device-drawer-customer-select"
                    value={value}
                    disabled={loading}
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                )}
              />
              <Controller
                name="siteId"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSelect
                    className="modify-device-drawer__site-id-select"
                    inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.site')}
                    required={true}
                    menuItems={siteOptions}
                    disabled={loadingSites || loading}
                    helperText={errors.siteId?.message}
                    error={Boolean(errors.siteId)}
                    onBlur={onBlur}
                    onChange={(event) => {
                      onChange(event);
                      setValue('keepDataAtPreviousSite', true);
                    }}
                    data-testid="modify-device-drawer-site-select"
                    value={loadingSites ? '' : value}
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                )}
              />
              {watch('siteId') !== defaultValues.siteId && (
                <div
                  className="modify-device-drawer__keep-device-data"
                  data-testid="modify-device-drawer-keep-device-data-block"
                >
                  <div className="modify-device-drawer__keep-device-data-prompt">
                    <span className="modify-device-drawer__keep-device-data-warning">
                      {t('deviceAdminDetailsPage.modifyDeviceDrawer.keepDeviceDataWarning')}
                    </span>
                    <Controller
                      control={control}
                      name="keepDataAtPreviousSite"
                      render={({ field: { onBlur, onChange, value } }) => (
                        <RSSwitch
                          data-testid="modify-device-drawer-keep-device-data-switch"
                          checked={Boolean(value)}
                          onBlur={onBlur}
                          onChange={onChange}
                          disabled={loading}
                          falseValueLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.keepDeviceDataFalse')}
                          trueValueLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.keepDeviceDataTrue')}
                        />
                      )}
                    />
                  </div>
                  <span className="modify-device-drawer__keep-device-data-description">
                    {t('deviceAdminDetailsPage.modifyDeviceDrawer.keepDeviceDataDescription')}
                  </span>
                </div>
              )}
              <RSSelect
                className="modify-device-drawer__program-select"
                inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.program')}
                menuItems={[{ displayName: program || '', menuItemProps: { value: program || '' } }]}
                disabled={true}
                data-testid="modify-device-drawer-program-select"
                defaultValue={program || ''}
              />
              <Controller
                name="deviceTypeId"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSelect
                    className="modify-device-drawer__device-type-id-input"
                    inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.deviceType')}
                    required={true}
                    menuItems={deviceTypeOptions}
                    helperText={errors.deviceTypeId?.message}
                    error={Boolean(errors.deviceTypeId)}
                    onBlur={onBlur}
                    onChange={onChange}
                    data-testid="modify-device-drawer-device-type-select"
                    value={value}
                    disabled={loading}
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                )}
              />
              <Controller
                name="connectorHolderTypeId"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSelect
                    className="modify-device-drawer__connector-holder-type-input"
                    inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.connectorHolderType')}
                    menuItems={connectorHolderTypeOptions}
                    helperText={errors.connectorHolderTypeId?.message}
                    error={Boolean(errors.connectorHolderTypeId)}
                    onBlur={onBlur}
                    onChange={onChange}
                    data-testid="modify-device-drawer-connector-holder-type-select"
                    value={value || ''}
                    disabled={loading}
                    {...RS_SELECT_ADMIN_MENU_PROPS}
                  />
                )}
              />
              <RSTextInput
                inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.latitude')}
                type="number"
                helperText={errors.location?.latitude?.message}
                error={Boolean(errors.location?.latitude)}
                data-testid="modify-device-drawer-latitude-input"
                disabled={loading}
                {...register('location.latitude', { valueAsNumber: true })}
              />
              <RSTextInput
                inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.longitude')}
                type="number"
                helperText={errors.location?.longitude?.message}
                error={Boolean(errors.location?.longitude)}
                data-testid="modify-device-drawer-longitude-input"
                disabled={loading}
                {...register('location.longitude', { valueAsNumber: true })}
              />
              <Controller
                control={control}
                name="deactivated"
                render={({ field: { onBlur, onChange, value } }) => (
                  <RSSwitch
                    formLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.deactivated')}
                    data-testid="modify-device-drawer-deactivated-switch"
                    checked={Boolean(value)}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={loading}
                    falseValueLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.deactivatedFalse')}
                    trueValueLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.deactivatedTrue')}
                  />
                )}
              />
              <RSTextInput
                inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.description')}
                data-testid="modify-device-drawer-description-input"
                multiline={true}
                helperText={errors.description?.message}
                error={Boolean(errors.description)}
                disabled={loading}
                {...register('description')}
              />
              <RSTextInput
                inputLabel={t('deviceAdminDetailsPage.modifyDeviceDrawer.importedAt')}
                data-testid="modify-device-drawer-imported-at"
                disabled={true}
                defaultValue={createdAt}
              />
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loading}
              isCancelDisabled={loading}
              colorVariant="success"
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loading}
            disableCancelButton={loading}
            confirmButtonColor="success"
            cancelButtonColor="success"
          />
        </>
      )}
    </RSDrawer>
  );
};
