/* eslint-disable func-style */
import {
  AuthCustomer,
  AuthDevice,
  AuthDeviceGroup,
  AuthDeviceOperation,
  AuthSite,
  AuthUser,
  AuthUserDeviceGroup
} from './authz-checker.service';
import {
  GetDeviceWithGroupInfoQuery,
  GetUserAuthInfoQuery,
  UserDeviceGroupRole,
  UserPermissionType
} from '../../__generated__/graphql';

export type AuthSubjectInfo =
  | { type: 'DeviceOperation'; deviceId: string; operationName: string }
  | {
      type: 'Company';
      companyId?: string;
    }
  | {
      type: 'Site';
      customerId: string;
    }
  | {
      type: 'DeviceGroup';
      readonly customerId: string;
      readonly serviceProviderId?: string;
      readonly deviceGroupId?: string;
    }
  | {
      type: 'DeviceGroupDevice';
      readonly deviceId: string;
      readonly customerId: string;
      readonly serviceProviderId?: string;
      readonly deviceGroupDeviceId?: string;
    }
  | {
      type: 'Device';
      deviceId: string;
    }
  | {
      type: 'DeviceBasicDetails';
      deviceId: string;
    }
  | {
      type: 'DeviceSite';
      deviceId: string;
    }
  | {
      type: 'DeviceCloudSettings';
      deviceId: string;
    }
  | {
      type: 'User';
      email: string;
      companyId: string;
      isSuperUser: boolean;
      permissions: UserPermissionType[];
      id?: string;
    }
  | {
      type: 'UserDeviceGroup';
      userId: string;
      userRole: UserDeviceGroupRole;
      deviceGroupId: string;
      customerId: string;
      serviceProviderId: string;
    }
  | {
      type: 'ConnectorHolderType';
    }
  | {
      type: 'DeviceType';
    }
  | {
      type: 'RocAlertConfiguration';
      companyId: string;
    }
  | null;

export function wrapUserData(userData?: GetUserAuthInfoQuery | null): AuthUser | null {
  const user = userData?.users[0];
  if (!user) {
    return null;
  }
  return new AuthUser(
    user.email,
    user.companyId,
    user.isSuperUser,
    user.userPermissionLinks.map((upl) => upl.userPermission as UserPermissionType),
    user.userDeviceGroups.map((udg) => {
      const deviceGroup = udg.deviceGroup;
      return new AuthUserDeviceGroup(
        new AuthDeviceGroup(deviceGroup.customerId, deviceGroup.serviceProviderId || undefined, deviceGroup.id),
        udg.userRole as UserDeviceGroupRole
      );
    }),
    user.id,
    Boolean(user.company?.customer?.allowImport)
  );
}

export function wrapDeviceOperationData(
  deviceData: GetDeviceWithGroupInfoQuery | null | undefined,
  operationName: string
): AuthDeviceOperation | null {
  const device = deviceData?.deviceByPK;
  if (!device) {
    return null;
  }
  const wrappedDevice = wrapDeviceQueryData(deviceData);
  if (!wrappedDevice) {
    return null;
  }
  return new AuthDeviceOperation(wrappedDevice, operationName);
}

export function wrapDeviceQueryData(deviceData: GetDeviceWithGroupInfoQuery | null | undefined): AuthDevice | null {
  const device = deviceData?.deviceByPK;
  if (!device) {
    return null;
  }

  return new AuthDevice(
    new AuthSite(new AuthCustomer(device.site.customer?.companyId, device.site.customer?.allowImport)),
    device.deviceGroupDevices.map((dgd) => {
      const deviceGroup = dgd.deviceGroup;
      return new AuthDeviceGroup(deviceGroup.customerId, deviceGroup.serviceProviderId || undefined, deviceGroup.id);
    })
  );
}
